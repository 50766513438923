<template>
  <PrimePage :loading="isLoading">
    <ion-row class="full-height">
      <ion-col>
        <ion-content>
          <div class="prime-view-container">
            <div class="prime-full-width-container prime-roster-container">
              <ion-row>
                <ion-col>
                  <h2 class="view-title">User Administration</h2>
                </ion-col>
                <ion-col size="auto" class="pad-twenty display-flex valign">
                  <button v-if="$can(I.ADD_USER)" class="prime-button" @click="addUserAction">
                    <IconPersonAdd class="icon-small" />
                    Add New User
                  </button>
                </ion-col>
              </ion-row>
              <ion-card color="white">
                <ion-card-header>
                  <div class="searchBox">
                    <!-- {{ rows }} -->
                    <form>
                      <ion-grid>
                        <ion-row>
                          <ion-col size="2" class="margin-right-ten">
                            <SearchInput @SearchInputEvent="handleSearchEvent($event)" placeholder="Search for user..." context="professional" />
                          </ion-col>
                          <ion-col size="2" class="margin-right-ten">
                            <select class="form-control" @change="onPracticeChange($event)">
                              <option value="" disabled selected hidden>Filter by Practice</option>
                              <option value="">All Practices</option>
                              <option v-for="org in orgs" :key="org.id" :value="org.id">{{ org.name }}</option>
                            </select>
                          </ion-col>
                          <ion-col size="2" class="margin-right-ten">
                            <select class="form-control" @change="filterByRole($event)" required="">
                              <option value="" disabled selected hidden>Filter by Role</option>
                              <option value="">All Roles</option>
                              <option value="1">Physician</option>
                              <option value="2">Medical Director</option>
                              <option value="3">Nurse Practitioner</option>
                              <option value="4">Physician Assistant</option>
                              <option value="5">Administrator</option>
                              <option value="6">Case Manager</option>
                              <option value="7">Clinical Staff</option>
                              <option value="8">Office Manager</option>
                              <option value="9">Office Staff</option>
                            </select>
                          </ion-col>
                          <ion-col size="2" class="margin-right-ten">
                            <select class="form-control" v-model="searchEnrollmentStatus" @change="filterByStatus()" required="">
                              <option value="" disabled selected hidden>Filter by Status</option>
                              <option value="">All Statuses</option>
                              <option>Active</option>
                              <option>Inactive</option>
                            </select>
                          </ion-col>
                          <ion-col size="6"> </ion-col>
                        </ion-row>
                      </ion-grid>
                    </form>
                  </div>
                </ion-card-header>
                <div>
                  <vue-good-table
                    ref="vgt-table"
                    mode="remote"
                    :pagination-options="{
                      enabled: true
                    }"
                    :totalRows="totalRecords"
                    :columns="columns"
                    :rows="rows"
                    styleClass="prime-table vgt-table"
                    :search-options="{
                      enabled: false,
                      placeholder: 'Search Patients'
                    }"
                    :sort-options="{
                      enabled: true
                    }"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-per-page-change="onPerPageChange"
                    @on-search="onSearch"
                  >
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field == 'displayName'">
                        <div class="display-inline-block margin-right-five">
                          <div class="avatar" v-if="props.row">
                            {{ props.row.firstName.charAt(0) + props.row.lastName.charAt(0) }}
                          </div>
                        </div>
                        <!-- {{ props.row.displayName }} -->
                        {{ makeName(props.row) }}
                      </span>
                      <span v-else-if="props.column.field == 'active'" class="text-center text-bold pill" :class="{ 'blue-pill': props.row.active }">
                        {{ props.row.active ? "Active" : "Inactive" }}
                      </span>
                      <span v-else-if="props.column.field == 'pcpLastName'">
                        {{ makePcpName(props.row) }}
                      </span>
                      <span v-else-if="props.column.field == 'adminUser'" class="text-center text-bold pill" :class="{ 'green-pill': props.row.adminUser }">
                        {{ props.row.adminUser ? "YES" : "NO" }}
                      </span>
                      <span v-else-if="$can(I.MODIFY_USER) && props.column.field == 'patientOptionsButton' && props.row.active">
                        <button @click="showPopover($event, props.row)" class="prime-button button-clear" v-tooltip.top="'Administration Options'">
                          <ion-icon name="md-more"></ion-icon>
                        </button>
                      </span>
                    </template>
                  </vue-good-table>
                </div>
              </ion-card>
            </div>
          </div>
        </ion-content>
      </ion-col>
    </ion-row>
  </PrimePage>
</template>

<script>
import { addIcons } from "ionicons";
import { add, more } from "ionicons/icons";
import { send as httpSend } from "@/services/Api";
import { EventBus } from "@/services/Events.js";
import SearchInput from "@/components/Global/SearchInput";
import popoverRosterProfessional from "@/components/Roster/PopoverRosterProfessional";
import PopoverAddNewUser from "@/components/Roster/PopoverAddNewUser";
import IconPersonAdd from "@/components/Global/Icons/IconPersonAdd";
import ModalAddUser from "@/components/Roster/ModalAddUser";
import ModalAddInternalUser from "@/components/Roster/ModalAddInternalUser";
import PrimePage from "@/components/Global/PrimePage";

addIcons({
  "md-add": add.md,
  "md-more": more.md
});

export default {
  name: "Roster",
  components: {
    PrimePage,
    SearchInput,
    IconPersonAdd
  },
  data() {
    return {
      columns: [
        {
          label: "Name",
          field: "displayName",
          tdClass: "bold",
          sortable: true
        },
        {
          label: "Practice",
          field: "practiceName",
          width: "260px",
          sortable: true
        },
        {
          label: "Role",
          field: "professionalRole",
          width: "260px",
          sortable: true
        },
        {
          label: "Account Status",
          field: "active",
          width: "150px",
          tdClass: "prime-roster-action-button-container",
          sortable: true
        },
        {
          label: "Admin User",
          field: "adminUser",
          width: "150px",
          tdClass: "prime-roster-action-button-container",
          sortable: true
        },
        {
          label: "",
          field: "patientOptionsButton",
          thClass: "prime-roster-nosort",
          tdClass: "prime-roster-options-button-container",
          width: "35px",
          sortable: false
        }
      ],
      rows: [
        // row data will get injected here
      ],
      isLoading: false,
      searchPatient: "",
      searchEnrollmentStatus: "",
      totalRecords: 0,
      orgs: undefined,
      serverParams: {
        id: "0",
        page: "1",
        perPage: "10",
        columnFilters: {},
        sort: []
      }
    };
  },
  computed: {
    currentProfessional() {
      return this.$store.getters["currentProfessional/get"];
    },
    addUserAction() {
      return this.$can(this.I.ADD_USER) ? this.showAddUserPopover : this.openAddPatientModal;
    }
  },
  mounted() {
    this.isLoading = true;
    this.getItems();

    EventBus.$on("reloadProfessionalRoster", () => {
      this.getItems();
    });
    this.getMyOrgs();
  },
  methods: {
    handleSearchEvent(payload) {
      this.searchPatient = payload.columnFilters.lastName;
      this.updateParams({ page: 1, columnFilters: this.buildSearch() });
      this.getItems();
    },
    buildSearch() {
      let colFilters = {};
      if (this.searchPatient.length > 0) {
        colFilters.lastName = this.searchPatient;
        colFilters.firstName = this.searchPatient;
      }
      if (this.searchEnrollmentStatus.length > 0) {
        colFilters.active = this.searchEnrollmentStatus == "Active";
      }

      return colFilters;
    },
    filterByStatus() {
      this.$refs["vgt-table"].reset();
      this.updateParams({ page: 1, columnFilters: this.buildSearch() });
      this.getItems();
    },
    getItems() {
      const method = "post";
      // const path = document.config.rosterApi + this.$authState.getClaims().professionalId;
      const path = document.config.professionalRoster;
      const payload = this.serverParams;

      httpSend({ path, method, payload, authToken: true })
        .then(response => {
          this.rows = response.data.results;
          this.totalRecords = response.data.totalRecords;
          this.isLoading = false;
        })
        .catch(error => {
          this.isLoading = false;
          this.$ionic.toastController
            .create({
              header: "Failed to load Patient Roster",
              message: error,
              duration: 7000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    async getMyOrgs() {
      const method = "get";
      const path = document.config.myOrganizations;
      httpSend({ path, method, authToken: true })
        .then(response => {
          this.orgs = response.data;
          this.isLoading = false;
        })
        .catch(error => {
          this.isLoading = false;
          this.$ionic.toastController
            .create({
              header: "Failed to get your Organizations list",
              message: error,
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    onPracticeChange(evt) {
      this.serverParams.columnFilters.orgIds = evt.target.value;
      this.getItems();
    },
    filterByRole(evt) {
      this.serverParams.columnFilters.roleId = evt.target.value;
      this.getItems();
    },
    onRowClick(params) {
      this.$router.push({ path: "roster/" + params.row.beneficiaryId });
    },
    makeName(rowObj) {
      return rowObj.lastName + ", " + rowObj.firstName;
    },
    makePcpName(rowObj) {
      return rowObj.pcpTitle + " " + rowObj.pcpFirstName + " " + rowObj.pcpLastName;
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getItems();
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.getItems();
    },
    onSortChange(params) {
      if (params.field == "beneLastName") {
        params[1] = new {
          field: "beneFirstName",
          type: params[0].type
        }();
      } else if (params.field == "pcpLastName") {
        params[1] = new {
          field: "pcpFirstName",
          type: params[0].type
        }();
      }
      this.updateParams({
        sort: params
      });
      this.getItems();
    },
    onSearch(params) {
      this.updateParams({
        page: 1,
        columnFilters: {
          beneFirstName: params.searchTerm,
          beneLastName: params.searchTerm,
          mrn: params.searchTerm,
          dob: params.searchTerm
        }
      });
      this.getItems();
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.getItems();
    },
    openAddPatientModal: async function() {
      const modal = await this.$ionic.modalController.create({
        component: ModalAddUser,
        mode: "ios",
        componentProps: {
          propsData: {
            availableOrgs: this.orgs
          }
        }
      });

      return await modal.present();
    },
    showPopover: function(ev, currentUser) {
      this.$ionic.popoverController
        .create({
          component: popoverRosterProfessional,
          event: ev,
          mode: "ios",
          cssClass: "popover-width-sm",
          componentProps: {
            data: {
              currentUser: currentUser
            }
          }
        })
        .then(m => m.present());
    },
    showAddUserPopover(ev) {
      this.$ionic.popoverController
        .create({
          component: PopoverAddNewUser,
          event: ev,
          mode: "ios",
          cssClass: "popover-width-sm",
          componentProps: {
            propsData: {
              onAddNewPracticeUser: this.openAddPatientModal,
              onAddNewInternalUser: this.openAddInternalUserModal
            }
          }
        })
        .then(p => p.present());
    },
    openAddInternalUserModal() {
      this.$ionic.modalController
        .create({
          component: ModalAddInternalUser,
          mode: "ios",
          componentProps: {
            propsData: {
              onSuccess: this.getItems
            }
          }
        })
        .then(p => p.present());
    }
  }
};
</script>

<style scoped>
/* -------------------------------- */
/* Patient Roster ----------------- */
/* -------------------------------- */
.prime-roster-options-button-container {
  height: 60px;
}
.prime-roster-container ion-card {
  margin: 20px 0 !important;
  padding: 0;
}
.prime-roster-container ion-card ion-card-header {
  padding: 20px 20px 10px 20px;
}

.prime-roster-add-button {
  width: 160px;
  position: absolute;
  right: 40px;
  top: 90px;
}

/* Patient Roster Actions Buttons -- */
.prime-roster-action-button-container {
  position: relative;
}
button.prime-roster-action-button {
  margin-left: 34px;
  position: relative;
}
button.prime-roster-action-button::before {
  content: "";
  display: block;
  position: absolute;
  width: 15px;
  height: 15px;
  background: var(--ion-color-primary);
  border-radius: 14px;
  left: -30px;
  top: 11px;
}
button.prime-roster-action-button.button-secondary::before {
  display: none;
}

/* Patient Roster Options Buttons -- */
.prime-roster-options-button-container button.prime-button.button-clear {
  padding-left: 4px;
  padding-right: 4px;
  font-size: 18px;
}
.prime-roster-options-button-container button ion-icon {
  margin-right: 0;
}

/* -------------------------------- */
/* Patient Roster - Table --------- */
/* -------------------------------- */

.prime-table th,
.prime-table td {
  text-align: left;
}

.prime-table th {
  cursor: pointer;
}

.prime-table td {
  font-weight: 400;
}

.prime-table thead th {
  opacity: 0.25;
  transition: opacity 0.2s ease-in-out;
}

.prime-table thead th:hover {
  opacity: 0.35;
}

.prime-table th.sorting {
  cursor: pointer;
}

.prime-table thead th.sorting-asc,
.prime-table thead th.sorting-asc:hover,
.prime-table thead th.sorting-desc,
.prime-table thead th.sorting-desc:hover {
  opacity: 1;
}

.prime-table td {
  padding: 10px;
  vertical-align: middle;
  border-top: 2px solid var(--ion-color-light);
}

.prime-table td.bold {
  font-weight: 500;
}

.prime-table tr td:first-child,
.prime-table tr th:first-child {
  padding-left: 30px;
}

.prime-table tr td:last-child,
.prime-table tr th:last-child {
  padding-right: 10px;
}

.prime-table th:after {
  content: url(../../assets/sort.svg);
  display: inline-block;
  vertical-align: text-bottom;
  margin-left: 5px;
  height: 20px;
  width: 20px;
}

.prime-table th.prime-roster-nosort:after {
  content: "";
}

.prime-table th.sorting:after {
  content: url(../../assets/arrow-down.svg);
  display: inline-block;
  vertical-align: text-bottom;
  margin-left: 5px;
  height: 20px;
  width: 20px;
  transition: transform 0.2s ease-in-out;
}

.prime-table thead th.sorting-asc:after {
  transform: rotate(0deg);
}

.prime-table thead th.sorting-desc:after {
  transform: rotate(180deg);
}

.prime-table-left-align,
.vgt-left-align {
  text-align: left;
}

.prime-table-right-align,
.vgt-right-align {
  text-align: right;
}

.prime-table-center-align {
  text-align: center;
}

.vgt-pull-left {
  float: left !important;
}

.vgt-pull-right {
  float: right !important;
}
.vgt-clearfix::after {
  display: block;
  content: "";
  clear: both;
}

.vgt-responsive {
  width: 100%;
  overflow-x: auto;
  position: relative;
}

.vgt-text-disabled {
  color: #909399;
}

.vgt-wrap {
  position: relative;
}

.vgt-fixed-header {
  position: absolute;
  z-index: 10;
  width: 100%;
  overflow-x: auto;
}

table.vgt-table {
  font-size: 16px;
  border-collapse: collapse;
  background-color: #fff;
  width: 100%;
  max-width: 100%;
  table-layout: auto;
}

table.vgt-table tr.clickable {
  cursor: pointer;
}

table.vgt-table tr.clickable:hover {
  background-color: var(--ion-color-white-shade);
}

.vgt-table th {
  padding: 0.75em 1.5em 0.75em 0.75em;
  vertical-align: middle;
  position: relative;
}

.vgt-table th.line-numbers,
.vgt-table th.vgt-checkbox-col {
  padding: 0 0.75em 0 0.75em;
  color: #606266;
  border-right: 1px solid #dcdfe6;
  word-wrap: break-word;
  width: 25px;
  text-align: center;
  background: linear-gradient(#f4f5f8, #f1f3f6);
}

.vgt-table th.filter-th {
  padding: 0.75em 0.75em 0.75em 0.75em;
}

.vgt-table th.vgt-row-header {
  border-bottom: 2px solid #dcdfe6;
  border-top: 2px solid #dcdfe6;
  background-color: #fafafb;
}

.vgt-table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #dcdfe6;
  padding-right: 1.5em;
  /* background: linear-gradient(#f4f5f8, #f1f3f6) */
}

.vgt-table thead th.vgt-checkbox-col {
  vertical-align: middle;
}

.vgt-loading {
  position: absolute;
  width: 100%;
  z-index: 10;
  margin-top: 117px;
}

.vgt-loading__content {
  background-color: #c0dfff;
  color: #409eff;
  padding: 7px 30px;
  border-radius: 3px;
}

.vgt-inner-wrap.is-loading {
  opacity: 0.5;
  pointer-events: none;
}

.vgt-table.bordered td,
.vgt-table.bordered th {
  border-top: 1px solid #dcdfe6;
}

.vgt-table.bordered th.vgt-row-header {
  border-bottom: 3px solid #dcdfe6;
}

.vgt-table.striped tbody tr:nth-of-type(odd) {
  background-color: rgba(51, 68, 109, 0.03);
}

.vgt-wrap.rtl {
  direction: rtl;
}

.vgt-wrap.rtl .vgt-table td,
.vgt-wrap.rtl .vgt-table th:not(.line-numbers) {
  padding: 0.75em 0.75em 0.75em 1.5em;
}

.vgt-wrap.rtl .vgt-table thead th,
.vgt-wrap.rtl .vgt-table.condensed thead th {
  padding-left: 1.5em;
  padding-right: 0.75em;
}

.vgt-wrap.rtl .vgt-table th.sorting-asc:after,
.vgt-wrap.rtl .vgt-table th.sorting:after {
  margin-right: 5px;
  margin-left: 0;
}

.vgt-table.condensed td,
.vgt-table.condensed th.vgt-row-header {
  padding: 0.4em 0.4em 0.4em 0.4em;
}

.vgt-global-search {
  padding: 5px 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  border: 1px solid #dcdfe6;
  border-bottom: 0;
  background: linear-gradient(#f4f5f8, #f1f3f6);
}

.vgt-global-search__input {
  position: relative;
  padding-left: 40px;
  flex-grow: 1;
}

.vgt-global-search__input .input__icon {
  position: absolute;
  left: 0;
  max-width: 32px;
}

.vgt-global-search__input .input__icon .magnifying-glass {
  margin-top: 3px;
  margin-left: 8px;
  display: block;
  width: 16px;
  height: 16px;
  border: 2px solid #d6dae2;
  position: relative;
  border-radius: 50%;
}

.vgt-global-search__input .input__icon .magnifying-glass:before {
  content: "";
  display: block;
  position: absolute;
  right: -7px;
  bottom: -5px;
  background: #d6dae2;
  width: 8px;
  height: 4px;
  border-radius: 2px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}

.vgt-global-search__actions {
  margin-left: 10px;
}

.vgt-selection-info-row {
  background: #fdf9e8;
  padding: 5px 16px;
  font-size: 13px;
  border-top: 1px solid #dcdfe6;
  border-left: 1px solid #dcdfe6;
  border-right: 1px solid #dcdfe6;
  color: #d3aa3b;
  font-weight: 700;
}

.vgt-selection-info-row a {
  font-weight: 700;
  display: inline-block;
  margin-left: 10px;
}

.vgt-wrap__actions-footer {
  border: 1px solid #dcdfe6;
}

.vgt-wrap__footer {
  color: var(--ion-color-medium-shade);
  padding: 20px;
  background: var(--ion-color-light-tint);
}

.vgt-wrap__footer .footer__row-count__label,
.vgt-wrap__footer .footer__row-count__select {
  display: inline-block;
  vertical-align: middle;
}

.vgt-wrap__footer .footer__row-count__label {
  font-size: 14px;
  color: #909399;
}

.vgt-wrap__footer .footer__row-count__select {
  background-color: transparent;
  width: auto;
  padding: 0;
  border: 0;
  border-radius: 0;
  height: auto;
  font-size: 14px;
  margin-left: 8px;
  color: var(--ion-color-medium-shade);
  font-weight: 700;
}

.vgt-wrap__footer .footer__row-count__select:focus {
  outline: 0;
  border-color: var(--ion-color-primary);
}

.vgt-wrap__footer .footer__navigation {
  font-size: 14px;
}

.vgt-wrap__footer .footer__navigation__info,
.vgt-wrap__footer .footer__navigation__page-btn,
.vgt-wrap__footer .footer__navigation__page-info {
  display: inline-block;
  vertical-align: middle;
}

.vgt-wrap__footer .footer__navigation__page-btn {
  text-decoration: none;
  color: var(--ion-color-dark);
  font-weight: 700;
  white-space: nowrap;
}

.vgt-wrap__footer .footer__navigation__page-btn:focus {
  outline: 0;
  border: 0;
}

.vgt-wrap__footer .footer__navigation__page-btn.disabled,
.vgt-wrap__footer .footer__navigation__page-btn.disabled:hover {
  opacity: 0.5;
  cursor: not-allowed;
}

.vgt-wrap__footer .footer__navigation__page-btn.disabled .chevron.left:after,
.vgt-wrap__footer .footer__navigation__page-btn.disabled:hover .chevron.left:after {
  border-right-color: var(--ion-color-dark);
}

.vgt-wrap__footer .footer__navigation__page-btn.disabled .chevron.right:after,
.vgt-wrap__footer .footer__navigation__page-btn.disabled:hover .chevron.right:after {
  border-left-color: var(--ion-color-dark);
}

.vgt-wrap__footer .footer__navigation__page-btn .chevron {
  width: 24px;
  height: 24px;
  border-radius: 15%;
  position: relative;
  margin: 0 8px;
}

.vgt-wrap__footer .footer__navigation__page-btn .chevron:after {
  content: "";
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  margin-top: -6px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
}

.vgt-wrap__footer .footer__navigation__page-btn .chevron.left::after {
  border-right: 6px solid var(--ion-color-primary);
  margin-left: -3px;
}

.vgt-wrap__footer .footer__navigation__page-btn .chevron.right::after {
  border-left: 6px solid var(--ion-color-primary);
  margin-left: -3px;
}

.vgt-wrap__footer .footer__navigation__info,
.vgt-wrap__footer .footer__navigation__page-info {
  display: inline-block;
  color: #909399;
  margin: 0 16px;
}

.vgt-wrap__footer .footer__navigation__page-info__current-entry {
  width: 30px;
  text-align: center;
  display: inline-block;
  margin: 0 10px;
  font-weight: 700;
}

@media only screen and (max-width: 750px) {
  .vgt-wrap__footer .footer__navigation__info {
    display: none;
  }
  .vgt-wrap__footer .footer__navigation__page-btn {
    margin-left: 16px;
  }
}
</style>
