<template>
  <div class="prime-modal">
    <ion-header>
      <ion-toolbar>
        <h5 slot="start">Add New Lumeris User {{ isCompleted ? "- Completed" : "" }}</h5>
        <ion-button slot="end" mode="ios" @click="closeModal()" expand="full" color="medium" fill="clear"><ion-icon name="md-close"></ion-icon></ion-button>
      </ion-toolbar>
      <div v-if="errors.length > 0" class="errors margin-lr-ten">
        <IconWarning />
        <div>
          <div v-for="error in errors" :key="error">
            {{ error + " " }}
          </div>
        </div>
      </div>
    </ion-header>
    <ion-content class="prime-add-patient-pages">
      <div class="prime-add-patient-slide-content">
        <ion-grid v-if="!isCompleted">
          <ion-row>
            <ion-col class="ion-margin-end" size="3">
              <h6>Prefix</h6>
              <select class="form-control" v-model="dataModel.title">
                <option v-for="option in $languagePack.titleOptions" :key="option" :selected="option === dataModel.title" :value="option">{{ option }}</option>
              </select>
            </ion-col>
            <ion-col>
              <h6>First Name</h6>
              <input class="form-control" type="text" placeholder="Enter First Name" v-model.trim="dataModel.firstName" />
            </ion-col>
          </ion-row>
          <ion-row class="margin-top-ten">
            <ion-col>
              <h6>Last Name</h6>
              <input class="form-control" type="text" placeholder="Enter Last Name" v-model.trim="dataModel.lastName" />
            </ion-col>
            <ion-col class="ion-margin-start" size="3">
              <h6>Suffix</h6>
              <select class="form-control" v-model="dataModel.suffix">
                <option v-for="option in $languagePack.suffixOptions" :key="option" :selected="option === dataModel.suffix" :value="option">{{ option }}</option>
              </select>
            </ion-col>
          </ion-row>
          <ion-row class="margin-top-ten">
            <ion-col>
              <h6>Role</h6>
              <select class="form-control" v-model="dataModel.systemRole">
                <option v-for="(name, id) in $languagePack.systemRoles" :key="id" :value="id">{{ name }}</option>
              </select>
            </ion-col>
          </ion-row>
          <ion-row class="margin-top-ten">
            <ion-col>
              <h6>Email Address</h6>
              <input class="form-control" type="text" placeholder="example@example.com" v-model.trim="email" />
            </ion-col>
          </ion-row>
          <ion-row class="margin-top-ten valign">
            <ion-col>
              <h6>Admin Permissions</h6>
            </ion-col>
            <ion-col size="auto">
              <ion-row class="valign">
                <ion-col>{{ dataModel.isUserAdmin ? "Yes" : "No" }}</ion-col>
                <ion-col>
                  <ion-toggle @ionChange="dataModel.isUserAdmin = $event.detail.checked" :checked="dataModel.isUserAdmin"></ion-toggle>
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
          <ion-row class="prime-form-block-description">
            <ion-col>
              {{ copy.adminPermissions }}
            </ion-col>
          </ion-row>
              <ion-row class="margin-top-ten display-flex valign">
              <ion-col>
                <h6>SSO-Enabled Account</h6>
              </ion-col>
              <ion-col size="auto">
                <ion-row class="display-flex valign">
                  <ion-col>{{ dataModel.isSSO ? "Yes" : "No" }}</ion-col>
                  <ion-col>
                    <ion-toggle @ionChange="dataModel.isSSO = $event.detail.checked" :checked="dataModel.isSSO"></ion-toggle>
                  </ion-col>
                </ion-row>
              </ion-col>
            </ion-row>
            <ion-row class="text-muted">
              <ion-col>
                SSO users will be authenticated externally. If set to 'no', the user will receive an email to set up a password.
              </ion-col>
            </ion-row>
        </ion-grid>
        <div v-else class="text-center pad-thirty margin-top-fourty">
          <IconCheckCircle size="xlg" color="success" />
          <h6>All done!</h6>
          <p>{{ copy.successMessage(displayName) }}</p>
        </div>
      </div>
    </ion-content>
    <ion-footer>
      <ion-grid>
        <ion-row v-if="!isCompleted">
          <ion-col>
            <button @click="closeModal()" class="prime-button button-secondary button-block">
              Cancel
            </button>
          </ion-col>
          <ion-col>
            <button @click="checkForm()" class="prime-button button-block" :class="{ 'button-pending': addUserLoading }">
              <ion-spinner name="dots" duration="1200" color="white"></ion-spinner>
              <span>Finish</span>
            </button>
          </ion-col>
        </ion-row>
        <ion-row v-else>
          <ion-col>
            <button @click="closeModal()" class="prime-button button-block">
              Close
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
  </div>
</template>

<script>
import { send as httpSend } from "@/services/Api";
import IconWarning from "@/components/Global/Icons/IconWarning";
import IconCheckCircle from "@/components/Global/Icons/IconCheckCircle";
import { formatDisplayName } from "@/utils/formatter";

export default {
  name: "ModalAddInternalUser",
  components: {
    IconWarning,
    IconCheckCircle
  },
  props: {
    onSuccess: Function
  },
  data() {
    return {
      dataModel: {
        firstName: null,
        lastName: null,
        email: null,
        suffix: null,
        title: null,
        systemRole: null,
        professionalRoleId: "5",
        isUserAdmin: true,
        isSSO: true
      },
      duplicateFound: false,
      addUserLoading: false,
      isCompleted: false,
      errors: [],
      copy: this.$languagePack.userAdministration
    };
  },
  computed: {
    email: {
      get() {
        return this.dataModel.email;
      },
      set(val) {
        this.dataModel.email = val;
        this.dataModel.username = val;
      }
    },
    displayName() {
      const { title, firstName, lastName, suffix } = this.dataModel;
      return formatDisplayName(title, firstName, lastName, suffix);
    }
  },
  methods: {
    closeModal: function() {
      this.$ionic.modalController.dismiss({ description: null });
    },
    patientDupeCheck: async function() {
      this.addUserLoading = true;
      const method = "put";
      const path = document.config.userDuplicateCheck;
      const payload = this.dataModel;

      httpSend({ path, method, authToken: true, payload })
        .then(() => {
          this.addUser();
        })
        .catch(error => {
          if (error.message) {
            this.errors.push("A user with this information appears to already be in the system");
          } else {
            this.$ionic.toastController
              .create({
                header: "Error",
                message: "There was a problem while checking for a duplicate user. Please try again later.",
                duration: 4000,
                position: "top"
              })
              .then(m => m.present());
          }
          this.addUserLoading = false;
        });
    },
    addUser: function() {
      this.addUserLoading = true;
      const method = "put";
      const path = document.config.newUser;
      const payload = this.dataModel;

      httpSend({ path, method, authToken: true, payload })
        .then(() => {
          this.isCompleted = true;
          this.onSuccess && this.onSuccess();
        })
        .catch(error => {
          this.errors.push(error.response.data.message);
        })
        .finally(() => (this.addUserLoading = false));
    },
    checkForm: function() {
      //reset errors
      this.errors = [];

      if (!this.dataModel.firstName) {
        this.errors.push("First Name is required");
      }
      if (!this.dataModel.lastName) {
        this.errors.push("Last Name is required");
      }
      if (!this.dataModel.email) {
        this.errors.push("Email Address is required");
      }
      if (!this.dataModel.systemRole) {
        this.errors.push("Please select a Role");
      }

      if (!this.errors.length) {
        this.patientDupeCheck();
      }
    }
  }
};
</script>

<style scoped>
.prime-add-patient-pages {
  --padding-start: 0;
  --padding-end: 0;
  --padding-bottom: 0;
}
.prime-add-patient-pages .prime-add-patient-slide-content {
  text-align: left;
  width: 100%;
  padding: 0 20px 20px;
}
.prime-add-patient-pages .prime-add-patient-slide-content h6 {
  margin-bottom: 5px;
}
.prime-add-patient-pages .prime-add-patient-slide-content input {
  margin-bottom: 0;
}
ion-footer {
  padding: 20px;
}
ion-footer ion-col:first-child {
  padding-right: 5px;
}
ion-footer ion-col:not(:first-child) {
  padding-left: 5px;
}

.errors {
  color: var(--ion-color-danger);
  font-weight: 500;
  background: var(--ion-color-danger-contrast);
  padding: 15px 20px;
  display: flex;
  text-align: left;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 10px;
}
.errors svg {
  fill: var(--ion-color-danger);
  width: 32px;
  margin-right: 10px;
}

small {
  font-size: smaller !important;
}
</style>
