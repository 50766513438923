<template>
  <div class="prime-modal">
    <ion-header>
      <ion-toolbar>
        <IconPersonAdd slot="start" class="icon-small" />
        <h5 slot="start">Add New User</h5>
        <ion-button slot="end" mode="ios" @click="closeModal()" expand="full" color="primary" fill="clear"><ion-icon name="md-close"></ion-icon></ion-button>
      </ion-toolbar>
      <div v-if="errors.length > 0" class="errors margin-lr-ten">
        <IconWarning />
        <div>
          <div v-for="error in errors" :key="error">
            {{ error + " " }}
          </div>
        </div>
      </div>
    </ion-header>
    <ion-content class="prime-add-patient-pages">
      <!-- {{ dataModel }} -->
      <div class="prime-add-patient-slide-content" v-if="duplicateFound">
        <div class="notifications-well margin-top-twenty">
          <h6 class="margin-top-five">
            A user with this information appears to already be in the system
          </h6>
        </div>
        <p>
          Use the "Back" button below to try again, or "Close" to cancel and return to the admin panel.
        </p>
      </div>
      <div class="prime-add-patient-slide-content" v-else>
        <ion-grid>
          <ion-row>
            <ion-col class="ion-margin-end" size="2">
              <h6>Prefix</h6>
              <select class="form-control" v-model="dataModel.title" required="">
                <option></option>
                <option>Dr.</option>
                <option>Mr.</option>
                <option>Mrs.</option>
              </select>
            </ion-col>
            <ion-col>
              <h6>First Name</h6>
              <input class="form-control" type="text" placeholder="Enter First Name" v-model.trim="dataModel.firstName" />
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <h6>Last Name</h6>
              <input class="form-control" type="text" placeholder="Enter Last Name" v-model.trim="dataModel.lastName" />
            </ion-col>
            <ion-col class="ion-margin-start" size="2">
              <h6>Suffix</h6>
              <select class="form-control" v-model="dataModel.suffix" required="">
                <option v-for="option in $languagePack.suffixOptions" :key="option" :selected="option === dataModel.suffix" :value="option">{{ option }}</option>
              </select>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <h6>Role</h6>
              <select class="form-control" v-model="dataModel.professionalRoleId" @change="dataModel.professionalRoleId = $event.target.value" required="">
                <option value="" selected>Select Role</option>
                <option value="1">Physician</option>
                <option value="2">Medical Director</option>
                <option value="3">Nurse Practitioner</option>
                <option value="4">Physician Assistant</option>
                <option value="5">Administrator</option>
                <option value="6">Case Manager</option>
                <option value="7">Clinical Staff</option>
                <option value="8">Office Manager</option>
                <option value="9">Office Staff</option>
              </select>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <h6>National Provider Identifier (optional)</h6>
              <input class="form-control" type="text" placeholder="Enter NPI" v-model.trim="dataModel.npi" />
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <h6>Email Address</h6>
              <input class="form-control" type="text" placeholder="example@example.com" v-model.trim="dataModel.email" />
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <h6>Phone Number - Text Messages</h6>
              <input class="form-control" type="text" placeholder="Enter Phone Number" v-model.trim="dataModel.smsPhoneNumber" />
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <h6>Practice</h6>
              <!-- {{professionals}} -->
              <select class="form-control" v-model="dataModel.organizationId" @change="onPracticeChange($event.target.value)">
                <option value="">Choose a Practice</option>
                <option v-for="org in availableOrgs" :key="org.id" :value="org.id">{{ org.name }}</option>
              </select>
            </ion-col>
          </ion-row>
          <ion-row class="margin-top-ten display-flex valign">
            <ion-col>
              <h6>Admin User</h6>
            </ion-col>
            <ion-col size="auto">
              <ion-row class="display-flex valign">
                <ion-col>{{ dataModel.isUserAdmin ? "Yes" : "No" }}</ion-col>
                <ion-col>
                  <ion-toggle @ionChange="dataModel.isUserAdmin = $event.detail.checked" :checked="dataModel.isUserAdmin"></ion-toggle>
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
          <ion-row class="text-muted">
            <ion-col>
              This will allow a user to add or remove other users at their practice.
            </ion-col>
          </ion-row>
          <ion-row class="margin-top-ten display-flex valign">
              <ion-col>
                <h6>SSO-Enabled Account</h6>
              </ion-col>
              <ion-col size="auto">
                <ion-row class="display-flex valign">
                  <ion-col>{{ dataModel.isSSO ? "Yes" : "No" }}</ion-col>
                  <ion-col>
                    <ion-toggle @ionChange="dataModel.isSSO = $event.detail.checked" :checked="dataModel.isSSO"></ion-toggle>
                  </ion-col>
                </ion-row>
              </ion-col>
            </ion-row>
            <ion-row class="text-muted">
              <ion-col>
                SSO users will be authenticated externally. If set to 'no', the user will receive an email to set up a password.
              </ion-col>
            </ion-row>
        </ion-grid>
      </div>
    </ion-content>
    <ion-footer>
      <ion-grid>
        <ion-row>
          <ion-col>
            <button @click="closeModal()" class="prime-button button-secondary button-block" v-if="stepNumber == 1 && !duplicateFound">
              Cancel
            </button>
            <button @click="duplicateFound = false" class="prime-button button-secondary button-block" v-else-if="duplicateFound">
              Back
            </button>
            <button @click="previousStep()" class="prime-button button-secondary button-block" v-else>
              Back
            </button>
          </ion-col>
          <ion-col>
            <button v-if="duplicateFound && stepNumber !== 3" @click="closeModal()" class="prime-button button-block">
              <span>Close</span>
            </button>
            <button @click="checkForm()" class="prime-button button-block" :class="{ 'button-pending': addUserLoading }" v-else>
              <ion-spinner name="dots" duration="1200" color="white"></ion-spinner>
              <span>Finish</span>
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
  </div>
</template>

<script>
import { addIcons } from "ionicons";
import { calendar } from "ionicons/icons";
import { send as httpSend } from "@/services/Api";
import { EventBus } from "@/services/Events";
import IconPersonAdd from "@/components/Global/Icons/IconPersonAdd";
import IconWarning from "@/components/Global/Icons/IconWarning";

addIcons({
  "md-calendar": calendar.md
});

export default {
  name: "ModalAddUser",
  components: {
    IconPersonAdd,
    IconWarning
  },
  props: {
    availableOrgs: Array
  },
  data() {
    return {
      stepNumber: 1,
      swiperOption: {
        allowTouchMove: false,
        spaceBetween: 40,
        autoHeight: false
      },
      ionSelectOptions: {
        mode: "ios",
        cssClass: "popover-width-sm"
      },
      dataModel: {
        firstName: null,
        lastName: null,
        email: null,
        npi: null,
        smsPhoneNumber: null,
        username: "forFutureUse",
        voicePhoneNumber: null,
        defaultCareTeamProfessionalId: null,
        professionalRoleId: null,
        organizationId: undefined,
        suffix: null,
        title: null,
        isUserAdmin: false,
        isSSO: true,
        systemRole: "PRACTICE"
      },
      duplicateFound: false,
      addUserLoading: false,
      professionals: [],
      defaultCareTeam: [],
      errors: [],
      availableCareTeam: [],
      copy: this.$languagePack.userAdministration
    };
  },
  methods: {
    pcpChange: function(pcpId) {
      this.dataModel.assignedProfessionalId = pcpId;
      this.getDefaultCareTeam(pcpId);
    },
    careTeamSelections: function(professional) {
      const index = this.dataModel.careTeamMemberIds.indexOf(professional.id);
      if (index > -1) {
        this.dataModel.careTeamMemberIds.splice(index, 1);
      } else {
        this.dataModel.careTeamMemberIds.push(professional.id);
      }
    },
    closeModal: function() {
      this.$ionic.modalController.dismiss({ description: null });
    },
    patientDupeCheck: async function() {
      this.addUserLoading = true;
      const method = "put";
      const path = document.config.userDuplicateCheck;
      const payload = this.dataModel;

      httpSend({ path, method, authToken: true, payload })
        .then(() => {
          this.addUser();
        })
        .catch(error => {
          if (error.message.length > 0) {
            this.duplicateFound = true;
          } else {
            this.$ionic.toastController
              .create({
                header: "Error",
                message: "There was a problem while checking for a duplicate user. Please try again later.",
                duration: 4000,
                position: "top"
              })
              .then(m => m.present());
          }
          this.addUserLoading = false;
        });
    },
    getAvailableCareTeamMembers: function(pcpId) {
      const t = this;
      this.availableCareTeam = this.professionals.filter(function(professional) {
        return professional.id != pcpId && !t.isDefaultCareTeamMember(professional.id);
      });
    },
    getDefaultCareTeam: function(pcpId) {
      const method = "get";
      const path = document.config.defaultCareTeam + pcpId;
      const payload = null;

      httpSend({ path, method, authToken: true, payload })
        .then(result => {
          this.defaultCareTeam = result.data;
          this.getAvailableCareTeamMembers(pcpId);
        })
        .catch(() => {
          this.errors.push("There was a problem trying to get the default care team list");
        });
    },
    isDefaultCareTeamMember: function(proId) {
      if (this.defaultCareTeam.length > 0) {
        return (
          this.defaultCareTeam.filter(function(professional) {
            return professional.id == proId;
          }).length > 0
        );
      }
      return false;
    },
    addUser: function() {
      this.addUserLoading = true;
      const method = "put";
      const path = document.config.newUser;
      const payload = this.dataModel;

      httpSend({ path, method, authToken: true, payload })
        .then(() => {
          this.addUserLoading = false;
          this.$ionic.toastController
            .create({
              header: "Success",
              message: "User was successfully added.",
              duration: 4000,
              color: "primary",
              mode: "ios"
            })
            .then(m => m.present());
          this.closeModal();
          EventBus.$emit("reloadProfessionalRoster");
        })
        .catch(error => {
          this.errors.push(error.response.data.message);
          this.addUserLoading = false;
        });
    },
    checkForm: function() {
      //reset errors
      this.errors = [];

      if (!this.dataModel.firstName) {
        this.errors.push("First Name is required");
      }
      if (!this.dataModel.lastName) {
        this.errors.push("Last Name is required");
      }
      if (!this.validateEmail()) {
        this.errors.push("Please enter a valid Email");
      }
      if (!this.dataModel.professionalRoleId) {
        this.errors.push("Please select a Role");
      }
      if (!this.dataModel.organizationId) {
        this.errors.push("Please select a Practice");
      }
      // if (!this.dataModel.smsPhoneNumber) {
      //   this.errors.push("Please enter a valid phone number");
      // } else if (!isValidPhoneNumber(this.dataModel.smsPhoneNumber)) {
      //   this.errors.push("Please enter a valid 10 digit phone number including area code");
      // } else {
      //   this.dataModel.smsPhoneNumber = formatPhoneNumber(this.dataModel.smsPhoneNumber);
      // }
      if (!this.errors.length) {
        this.patientDupeCheck();
      }
    },
    validateEmail() {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.dataModel.email)) {
        return 1;
      } else {
        return 0;
      }
    },
    onPracticeChange(evt) {
      this.dataModel.organizationId = evt;
    }
  },
  computed: {
    pcpsOnly: function() {
      return this.professionals.filter(function(member) {
        return member.pcp;
      });
    }
  },
  mounted: function() {
    // const slides = this.$refs.slider;
    // this.$nextTick(() => {
    //   slides.options = this.swiperOption;
    // });
  }
};
</script>

<style scoped>
.prime-modal-header-steps-container {
  font-weight: 500;
  font-size: 12px;
  color: var(--ion-color-medium-shade);
}
.prime-modal-header-steps-container span {
  margin-right: 10px;
}
.prime-modal-header-steps-pager {
  content: "";
  width: 8px;
  height: 8px;
  background: var(--ion-color-light-shade);
  border-radius: 8px;
  display: inline-block;
  margin-left: 10px;
}
.prime-modal-header-steps-pager.active {
  background: var(--ion-color-primary-tint);
}
.prime-add-patient-pages {
  --padding-start: 0;
  --padding-end: 0;
  --padding-bottom: 0;
}
.prime-add-patient-pages .swiper-slide {
  flex-direction: column;
  align-items: start;
}
.prime-add-patient-pages .prime-add-patient-slide-content {
  text-align: left;
  width: 100%;
  padding: 0 20px 20px;
}
.prime-add-patient-pages .prime-add-patient-slide-content h6 {
  margin-bottom: 5px;
}
.prime-add-patient-pages .prime-add-patient-slide-content input {
  margin-bottom: 0;
}
.prime-add-patient-pages .care-team-section {
  background: var(--ion-modal-secondary-background);
  border-top: 2px solid var(--ion-color-light-shade);
  /* min-height: 328px; */
  padding-top: 20px;
}
.swiper-slide {
  visibility: hidden;
}

.swiper-slide.swiper-slide-active {
  visibility: visible;
}
.prime-add-patient-pages .care-team-section::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  z-index: 0;
  height: 600px;
  background: var(--ion-modal-secondary-background);
}
ion-card.prime-professional-card {
  background: #ffffff;
  color: var(--ion-text-color);
  display: flex;
  align-items: center;
  margin: 0 0 10px 0 !important;
  box-shadow: 0 1px 6px 0px rgba(46, 61, 73, 0.2) !important;
  transition: all 0.2s ease-in-out;
}
ion-card.prime-professional-card:hover {
  box-shadow: 0 3px 15px 0px rgba(46, 61, 73, 0.3) !important;
}
ion-card.prime-professional-card .checkbox {
  margin-left: 5px;
}
ion-card.prime-professional-card .avatar {
  width: 50px;
  height: 50px;
  font-size: 18px;
  margin-left: 10px;
  margin-right: 20px;
}
ion-card.prime-professional-card .prime-professional-card-name ion-card.prime-professional-default-card .prime-professional-card-name {
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
ion-card.prime-professional-card .prime-professional-card-info ion-card.prime-professional-default-card .prime-professional-card-info {
  font-size: 12px;
  font-weight: 500;
  margin-top: 2px;
  color: var(--ion-color-medium-shade);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
ion-card.prime-professional-default-card {
  background: var(--ion-modal-secondary-background);
  color: var(--ion-text-color);
  display: flex;
  align-items: center;
  margin: 0 0 10px 0 !important;
  box-shadow: 0 1px 6px 0px rgba(46, 61, 73, 0.2) !important;
  transition: all 0.2s ease-in-out;
}
ion-card.prime-professional-default-card .avatar {
  width: 50px;
  height: 50px;
  font-size: 18px;
  margin-left: 10px;
  margin-right: 20px;
}
.prime-form-block-container {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
}
.prime-form-block-container .prime-form-block-title-row {
  display: flex;
  flex-direction: row;
}
.prime-form-block-container .prime-form-block-title {
  flex-grow: 1;
}
.prime-form-block-container .prime-form-block-title h6 {
  margin: 12px 0;
}
.prime-form-block-container .prime-form-block-input {
  display: flex;
  align-items: center;
}
.prime-form-block-container .prime-form-block-input span {
  font-size: 12px;
  font-weight: 700;
  margin-right: 10px;
}
.prime-form-block-container .prime-form-block-description {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--ion-color-medium-shade);
  margin-top: 2px;
}

ion-footer {
  padding: 20px;
}
ion-footer ion-col:first-child {
  padding-right: 5px;
}
ion-footer ion-col:not(:first-child) {
  padding-left: 5px;
}

.errors {
  color: var(--ion-color-danger);
  font-weight: 500;
  background: var(--ion-color-danger-contrast);
  padding: 15px 20px;
  display: flex;
  text-align: left;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 10px;
}
.errors svg {
  fill: var(--ion-color-danger);
  width: 32px;
  margin-right: 10px;
}
.add-patient-select {
  width: 100%;
  text-align: left;
  background: #ffffff;
  border: 2px solid var(--ion-color-light-shade);
  border-radius: 8px;
  padding: 8px 15px 7px;
  font-size: 14px;
  font-family: var(--ion-font-family);
  font-weight: 500;
  color: var(--ion-text-color);
  line-height: 1.5;
  margin-bottom: 15px;
  -webkit-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
  padding: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(../../assets/arrow-dropdown.svg) no-repeat right #fff;
  background-size: 15px;
  background-position-x: 365px;
}
</style>
