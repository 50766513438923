<template>
  <ion-list mode="ios">
    <ion-item lines="none" @click="goToUserProfile()" class="cursor-pointer">
      <ion-label color="dark" mode="ios">Edit User</ion-label>
      <IconPeople class="icon-small" />
      <!-- <ion-icon slot="end" name="ios-logout"></ion-icon> -->
    </ion-item>
    <ion-item lines="none" @click="confirmDeactivateUser()" class="cursor-pointer">
      <ion-label color="dark" mode="ios">{{ currentUser.active ? "Deactivate" : "Activate" }} User</ion-label>
      <IconDeactivate class="icon-small" />
    </ion-item>
  </ion-list>
</template>

<script>
import { EventBus } from "@/services/Events.js";
import { addIcons } from "ionicons";
import { logOut, settings } from "ionicons/icons";
import IconPeople from "@/components/Global/Icons/IconPeople";
import IconDeactivate from "@/components/Global/Icons/IconDeactivate";
import { send as httpSend } from "@/services/Api";

addIcons({
  "ios-logout": logOut.ios,
  "ios-settings": settings.ios
});

export default {
  name: "PopoverRosterProfessional",
  data() {
    return {
      currentUser: Object
    };
  },
  components: {
    IconPeople,
    IconDeactivate
  },
  methods: {
    goToUserProfile() {
      this.$ionic.popoverController.dismiss();
      this.$mypcpRouter.push({
        name: "user-profile",
        params: { id: this.currentUser.id }
      });
      // EventBus.$emit("userSettings");
    },
    confirmDeactivateUser() {
      this.$ionic.popoverController.dismiss();

      this.$ionic.alertController
        .create({
          header: "Confirm Deactivation",
          message: "Are you sure you want to deactivate <strong>" + (this.currentUser.lastName ? this.currentUser.lastName : "") + (this.currentUser.firstName ? ", " + this.currentUser.firstName : "") + "<strong>",
          mode: "ios",
          buttons: [
            {
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
              handler: () => {}
            },
            {
              text: "Deactivate",
              handler: () => {
                this.deactivateUser();
              }
            }
          ]
        })
        .then(a => a.present());
    },
    deactivateUser() {
      const method = "delete";
      const path = document.config.professional + this.currentUser.id;

      httpSend({ path, method, authToken: true })
        .then(() => {
          this.isLoading = false;
          this.$ionic.toastController
            .create({
              header: "Success",
              message: "User was successfully deactivated.",
              duration: 4000,
              color: "primary",
              mode: "ios"
            })
            .then(m => m.present());
          this.$ionic.popoverController.dismiss();
          EventBus.$emit("reloadProfessionalRoster");
        })
        .catch(error => {
          this.isLoading = false;
          this.$ionic.toastController
            .create({
              header: "Failed to load Patient Roster",
              message: error,
              duration: 7000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    }
  }
};
</script>

<style scoped>
ion-list.list-ios {
  margin-bottom: 0;
}
ion-item {
  padding: 0 5px;
}
ion-item:hover {
  background: var(--ion-color-light);
}
ion-label {
  font-weight: 500;
}
</style>
