<template>
  <input class="form-control" v-model="searchInput" :placeholder="placeholder" type="text" />
</template>

<script>
import _ from "lodash";

export default {
  name: "SearchInput",
  data() {
    return {
      searchInput: ""
    };
  },
  props: {
    debounceDelay: { type: Number, default: 1000 },
    placeholder: { type: String, default: "Search..." },
    context: { type: String, required: true }
  },
  watch: {
    searchInput: function() {
      _.debounce(this.emitEvent, this.debounceDelay, false)();
    }
  },
  methods: {
    emitEvent() {
      this.$emit("SearchInputEvent", this.getPayloadByContext());
    },
    getPayloadByContext() {
      const payloadGetters = {
        professional: this.getProfessionalPayload,
        pcp: this.getPCPPayload,
        patient: this.getPatientPayload
      };
      return payloadGetters[this.context]();
    },
    getProfessionalPayload() {
      const columnFilters = {};

      columnFilters.lastName = this.searchInput;

      const newPayload = { page: 1, columnFilters };
      return newPayload;
    },
    getPCPPayload() {
      const columnFilters = {};

      if (this.searchInput.length > 0) {
        columnFilters.pcpTitle = this.searchInput;
        columnFilters.pcpFirstName = this.searchInput;
        columnFilters.pcpLastName = this.searchInput;
      }

      const newPayload = { page: 1, columnFilters };
      return newPayload;
    },
    getPatientPayload() {
      const columnFilters = {};

      if (this.searchInput.length > 0) {
        columnFilters.beneFirstName = this.searchInput;
        columnFilters.beneLastName = this.searchInput;
        columnFilters.mrn = this.searchInput;
        columnFilters.dob = this.searchInput;
      }

      const newPayload = { page: 1, columnFilters };
      return newPayload;
    }
  }
};
</script>
