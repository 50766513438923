<template>
  <ion-list mode="ios">
    <ion-item lines="inset" @click="addNewPracticeUser()" class="cursor-pointer">
      <ion-label mode="ios">Add New Practice User</ion-label>
    </ion-item>
    <!-- <ion-item lines="none" @click="addNewInternalUser()" class="cursor-pointer">
      <ion-label mode="ios">Add New Lumeris User</ion-label>
    </ion-item> -->
  </ion-list>
</template>

<script>
export default {
  name: "PopoverAddNewUser",
  props: {
    onAddNewPracticeUser: Function,
    onAddNewInternalUser: Function
  },
  methods: {
    addNewPracticeUser() {
      this.onAddNewPracticeUser && this.onAddNewPracticeUser();
      this.$ionic.popoverController.dismiss();
    },
    addNewInternalUser() {
      this.onAddNewInternalUser && this.onAddNewInternalUser();
      this.$ionic.popoverController.dismiss();
    }
  }
};
</script>

<style scoped>
ion-list.list-ios {
  margin-bottom: 0;
}
ion-item {
  padding: 0 5px;
}
ion-item:hover {
  background: var(--ion-color-light);
}
ion-label {
  font-weight: 500;
}
</style>
